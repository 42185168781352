const MoreBtn = ({ text }) => {
  return (
    <button className="btn-52">
      <div className="original">{text}</div>
      <div className="letters">
        <span>A</span>
        <span>N</span>
        <span>D</span>
        <span>I</span>
        <span>A</span>
        <span>M</span>
        <span>O</span>
        <span>!</span>
      </div>
    </button>
  );
};
export default MoreBtn;
