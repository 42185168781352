import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/reducers/authSlice";

const useAuthUser = () => {
  const dispatch = useDispatch();

  const fetchCSRFToken = async () => {
    await axios.get("https://petsuperheroes.it/sanctum/csrf-cookie");
  };

  const saveToken = (user, token, rememberToken) => {
    dispatch(setAuth({ user, token, rememberToken }));
  };

  const http = axios.create({
    baseURL: "https://petsuperheroes.it/api/auth",
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });

  return {
    saveToken,
    http,
    fetchCSRFToken,
  };
};

export default useAuthUser;
