export const GET_DATA = "GET_DATA";
export const GET_IMAGES = "GET_IMAGES";

// ADS FETCH
export const getAds = () => {
  return (dispatch, getState) => {
    const URL = "https://petsuperheroes.it/api/ads";

    fetch(URL)
      .then((response) => response.json())

      .then((data) => dispatch({ type: GET_DATA, payload: data }))
      .catch((error) => console.log(error));
  };
};

// IMAGES FETCH
export const getIamges = () => {
  return (dispatch, getState) => {
    const URL = "https://petsuperheroes.it/api/images";

    fetch(URL)
      .then((response) => response.json())

      .then((data) => dispatch({ type: GET_IMAGES, payload: data }))
      .catch((error) => console.log(error));
  };
};
